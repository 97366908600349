import React, { useEffect, useState } from 'react';
import EditModal from '@core/modals/EditModal';
import EditButton from '@core/buttons/atoms/EditButton';
import Input from '@core/inputs/Input';
import { showError, showSuccess } from '@utils/helpers';
import TagService from '@services/TagService';

const EditTag = ({ tag, onEdited }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const closeModal = () => {
    setShowModal(false);
    setName('');
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    TagService.update(tag.id, name)
      .then(() => {
        showSuccess('Tag edited successfully');
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      TagService.find(tag.id).then((response) => {
        setName(response.data.data.name);
      });
  }, [tag.id, showModal]);

  return (
    <>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title="Edit tag"
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label="Edit">
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input height="h-10" label="Name" placeholder="Name" value={name} onChange={setName} />
        </div>
      </EditModal>
    </>
  );
};

export default EditTag;
