import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from '@pages/LoginPage';
import GuestRoute from '@router/GuestRoute';
import UsersPage from '@pages/UsersPage';
import AuthRoute from '@router/AuthRoute';
import HomePage from '@pages/HomePage';
import { Routes } from 'react-router';
import TagsPage from '@pages/TagsPage';
import CategoriesPage from '@pages/CategoriesPage';
import AttractionsPage from '@pages/AttractionsPage';
import DeleteAccountPage from '@pages/DeleteAccountPage';
import TermsAndConditionsPage from '@pages/TermsAndConditionsPage';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route element={<GuestRoute />}>
          <Route exact path="/login" element={<LoginPage />} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/users" element={<UsersPage />} />
          <Route exact path="/tags" element={<TagsPage />} />
          <Route exact path="/categories" element={<CategoriesPage />} />
          <Route exact path="/attractions" element={<AttractionsPage />} />
        </Route>
        <Route exact path="/delete-account" element={<DeleteAccountPage />} />
        <Route exact path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
