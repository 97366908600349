import React, { useEffect, useState } from 'react';
import EditModal from '@core/modals/EditModal';
import EditButton from '@core/buttons/atoms/EditButton';
import Input from '@core/inputs/Input';
import { showError, showSuccess } from '@utils/helpers';
import CategoryService from '@services/CategoryService';
import ImageInput from '@core/inputs/ImageInput';

const EditCategory = ({ category, onEdited }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);

  const baseUrl = process.env.REACT_APP_API_URL.replace('/api/', '');

  const closeModal = () => {
    setShowModal(false);
    setName('');
    setOriginalImage(null);
    setImage(null);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('icon', image);
    setLoading(true);
    CategoryService.update(category.id, formData)
      .then(() => {
        showSuccess('Category edited successfully');
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      CategoryService.find(category.id).then((response) => {
        setName(response.data?.data?.name);
        if (response.data?.data?.icon) {
          setOriginalImage(`${baseUrl}${response.data?.data?.icon}`);
        }
      });
  }, [baseUrl, category.id, showModal]);

  return (
    <>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title="Edit category"
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label="Edit">
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input height="h-10" label="Name" placeholder="Name" value={name} onChange={setName} />
          <div>
            <label className="leading-5 md:text-sm text-xs font-bold mr-3 flex-1">Icon</label>
            <ImageInput imagePath={originalImage} handleChange={(file) => setImage(file)} />
          </div>
        </div>
      </EditModal>
    </>
  );
};

export default EditCategory;
