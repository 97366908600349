import React, { useState } from 'react';
import AddButton from '@core/buttons/atoms/AddButton';
import AddModal from '@core/modals/AddModal';
import Input from '@core/inputs/Input';
import { showError, showSuccess } from '@utils/helpers';
import TagService from '@services/TagService';

const CreateTag = ({ onAdded }) => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName('');
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    TagService.create(name)
      .then(() => {
        showSuccess('Tag created successfully');
        onAdded();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <AddButton label="Add tag" onClick={() => setShowModal(true)} />
      <AddModal
        show={showModal}
        title="Add tag"
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}>
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input height="h-10" label="Name" placeholder="Name" value={name} onChange={setName} />
        </div>
      </AddModal>
    </>
  );
};

export default CreateTag;
