import React, { useState } from 'react';
import AuthService from '@services/AuthService';
import Input from '@core/inputs/Input';
import PasswordInput from '@core/inputs/PasswordInput';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { showError, showSuccess } from '@utils/helpers';

const DeleteAccountForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!confirmDelete) {
      return;
    }
    setDisabled(true);
    AuthService.deleteAccount(email, password)
      .then((res) => {
        setEmail('');
        setPassword('');
        setConfirmDelete(false);
        setShowMessage(true);
        showSuccess(res.data.message);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return (
    <>
      <div>
        <div className="flex justify-around items-center mb-4">
          <h1>LOGO</h1>
        </div>
        <h2 className="text-center text-2xl font-extrabold text-gray-900">DELETE ACCOUNT</h2>
        <p className="text-center text-sm text-gray-600 mt-2">
          Are you sure you want to delete your account? This action is irreversible and will
          permanently remove all your data.
        </p>
        {showMessage && (
          <div className="bg-green-100 rounded p-3 text-green-700 text-center mt-4">
            Your account has been successfully deleted.
          </div>
        )}
        <div className="flex items-center justify-center mt-4">
          <input
            type="checkbox"
            id="confirmDelete"
            className="mr-2"
            checked={confirmDelete}
            onChange={() => setConfirmDelete(!confirmDelete)}
          />
          <label htmlFor="confirmDelete" className="text-sm text-gray-700">
            I confirm that I want to delete my account.
          </label>
        </div>
      </div>
      <form className="mt-8 space-y-6" onSubmit={onSubmit}>
        <div>
          <Input
            label="Email"
            value={email}
            placeholder="Email"
            onChange={setEmail}
            extraClasses="xs:text-sm"
          />
          <div className="relative">
            <div className="w-full mt-2">
              <PasswordInput
                name="Fjalëkalimi"
                label="Fjalëkalimi"
                placeholder="Fjalëkalimi"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <DefaultButton
          disabled={!confirmDelete || disabled}
          bgColor="bg-[#cc0000]"
          bgColorHover="hover:bg-[#990000]"
          type="submit"
          label="CONFRIM ACCOUNT DELETION"
        />
      </form>
    </>
  );
};

export default DeleteAccountForm;
