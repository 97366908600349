import React, { useEffect, useState } from 'react';
import ViewModal from '@core/modals/ViewModal';
import ViewButton from '@core/buttons/atoms/ViewButton';
import RowDetails from '@hoc/cruds/RowDetails';
import TagService from '@services/TagService';

const ShowTag = ({ tag }) => {
  const [showModal, setShowModal] = useState(false);
  const [tagData, setTagData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      TagService.find(tag.id).then((response) => {
        setTagData(response.data.data);
      });
  }, [tag.id, showModal]);

  return (
    <>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal show={showModal} title="Overview" onClose={closeModal}>
        <div className="flex flex-col mt-5">
          <RowDetails label="Full name" value={tagData.name} />
        </div>
      </ViewModal>
    </>
  );
};

export default ShowTag;
