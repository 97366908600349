import React from 'react';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import AttractionService from '@services/AttractionService';
import ShowAttraction from '@components/Attraction/ShowAttraction';
import EditAttraction from '@components/Attraction/EditAttraction';
import ManageImages from '@components/Attraction/partials/ManageImages';

const AttractionActions = ({ attraction, onEdited, onDeleted, onManaged }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="mr-2">
        <ManageImages onManaged={onManaged} attraction={attraction} />
      </div>
      <div className="mr-2">
        <ShowAttraction attraction={attraction} />
      </div>
      <div className="mr-2">
        <EditAttraction attraction={attraction} onEdited={onEdited} />
      </div>
      <DeleteEntity service={AttractionService} id={attraction.id} onDeleted={onDeleted} />
    </div>
  );
};

export default AttractionActions;
