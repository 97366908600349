import React, { useState } from 'react';
import { showError } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import AuthService from '@services/AuthService';
import Input from '@core/inputs/Input';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import PasswordInput from '@core/inputs/PasswordInput';

const LoginForm = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(showSpinner('Please wait'));
    AuthService.adminLogin(email, password)
      .then(async (response) => {
        localStorage.setItem('accessToken', response.data.token);
        window.location.href = '/';
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  return (
    <>
      <div>
        <div className="flex justify-around items-center">
          <h1>LOGO</h1>
        </div>
        <h2 className="text-center text-3xl font-extrabold text-gray-900">Login to your account</h2>
      </div>
      <form className="mt-8 space-y-6" onSubmit={onSubmit}>
        <div className="rounded-md shadow-sm -space-y-px">
          <Input
            label="Email"
            value={email}
            placeholder="Email"
            className="rounded-b-none"
            onChange={setEmail}
            extraClasses="xs:text-sm md:text-lg"
          />
          <div className="relative">
            <div className="w-full mt-2">
              <PasswordInput
                name="Password"
                label="Password"
                placeholder="Password"
                textSize="text-xs"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <DefaultButton md type="submit" label="Login" />
      </form>
    </>
  );
};

export default LoginForm;
