import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Collapse } from 'react-collapse';
import TagService from '@services/TagService';
import { FaChevronDown, FaChevronRight, FaTimes } from 'react-icons/fa';

const SelectedTag = ({ tag, onRemove }) => {
  return (
    <div className="flex items-center m-1 bg-indigo-100 border-gray-200 shadow-sm uppercase text-xs rounded select-none hover:translate-y-0.5">
      <div className="py-1 mx-2">{tag.name}</div>
      <FaTimes
        size={20}
        className="text-gray-500 hover:bg-gray-900 hover:bg-opacity-5 cursor-pointer p-1 rounded-full"
        onClick={() => onRemove(tag)}
      />
    </div>
  );
};

const TagSelect = ({ selectedTags, isChecked, onCheckRemove, onCheck }) => {
  const [tags, setTags] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const getData = useCallback(() => {
    TagService.all().then((response) => {
      setTags(response.data?.data?.data);
    });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const onTagClick = (tag) => {
    if (isChecked(tag)) {
      onCheckRemove(tag);
    } else {
      onCheck(tag);
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col" ref={wrapperRef}>
      <div className="relative" onClick={() => setIsOpen(!isOpen)}>
        <div className="flex flex-row justify-between p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg cursor-pointer border-1 appearance-none">
          <span>Select tag</span>
          {isOpen ? <FaChevronRight /> : <FaChevronDown />}
        </div>
      </div>
      <Collapse isOpened={isOpen}>
        <div className="border-1 rounded-lg overflow-y-auto scrollbar-thin mt-5 scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full max-h-40">
          <div className="flex flex-col">
            {tags?.length > 0
              ? tags?.map((tag) => (
                  <div
                    key={tag.id}
                    onClick={() => onTagClick(tag)}
                    className={`transition duration-300 ease-in flex items-center last:border-0 border-b-1 border-dashed border-gray-200 px-5 py-3 cursor-pointer hover:bg-gray-50 ${
                      isChecked(tag) && 'text-indigo-600 font-bold'
                    }`}>
                    <div className="flex flex-col mr-3 flex-1 cursor-pointer">
                      <span className="leading-5 text-sm">{tag.name}</span>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </Collapse>
      <div className="flex flex-wrap mt-2">
        {selectedTags.map((tag) => (
          <div key={tag.id}>
            <SelectedTag tag={tag} onRemove={(tag) => onTagClick(tag)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagSelect;
