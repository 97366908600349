import React from 'react';
import AuthLayout from '@hoc/layouts/AuthLayout';
import DeleteAccountForm from '@components/DeleteAccount/DeleteAccountForm';

const DeleteAccountPage = () => {
  return (
    <AuthLayout>
      <DeleteAccountForm />
    </AuthLayout>
  );
};

export default DeleteAccountPage;
