import React, { useCallback, useEffect, useState } from 'react';
import EditModal from '@core/modals/EditModal';
import EditButton from '@core/buttons/atoms/EditButton';
import Input from '@core/inputs/Input';
import { showError, showSuccess } from '@utils/helpers';
import AttractionService from '@services/AttractionService';
import CategorySelect from '@components/Category/partials/CategorySelect';
import TagSelect from '@components/Tag/partials/TagSelect';

const EditAttraction = ({ attraction, onEdited }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [rating, setRating] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);

  const closeModal = () => {
    setShowModal(false);
    setName('');
    setSubTitle('');
    setDescription('');
    setLocationName('');
    setLatitude(null);
    setLongitude(null);
    setRating(null);
    setSelectedCategory(null);
    setSelectedTags([]);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('sub_title', subTitle);
    formData.append('description', description);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
    formData.append('location_name', locationName);
    formData.append('rating', rating);
    if (selectedCategory?.id) {
      formData.append('category_id', selectedCategory.id);
    }
    if (selectedTags.length) {
      selectedTags.forEach((tag, index) => {
        formData.append(`tags[${index}]`, tag.id);
      });
    }
    formData.append('category_id', selectedCategory.id);

    setLoading(true);
    AttractionService.update(attraction.id, formData)
      .then(() => {
        showSuccess('Attraction edited successfully');
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCheck = (option) => {
    setSelectedTags((prev) => [...prev, option]);
  };

  const onCheckRemove = (option) => {
    const filteredSelections = selectedTags.filter((tag) => tag.id !== option.id);
    setSelectedTags(filteredSelections);
  };

  const isChecked = useCallback(
    (option) => {
      return selectedTags.find((tag) => tag.id === option.id);
    },
    [selectedTags]
  );

  useEffect(() => {
    if (showModal)
      AttractionService.find(attraction.id).then((response) => {
        setName(response?.data?.data?.name);
        setSubTitle(response?.data?.data?.sub_title);
        setDescription(response?.data?.data?.description);
        setLatitude(response?.data?.data?.latitude);
        setLongitude(response?.data?.data?.longitude);
        setRating(response?.data?.data?.rating);
        setSelectedCategory(response?.data?.data.category);
        setLocationName(response?.data?.data.location_name);
        setSelectedTags(response?.data?.data?.tags);
      });
  }, [attraction.id, showModal]);

  return (
    <>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title="Edit Attraction"
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label="Edit">
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input height="h-10" label="Name" placeholder="Name" value={name} onChange={setName} />
          <Input
            height="h-10"
            label="Subtitle"
            placeholder="Subtitle"
            value={subTitle}
            onChange={setSubTitle}
          />
          <Input
            height="h-10"
            label="Description"
            placeholder="Description"
            value={description}
            onChange={setDescription}
          />
          <Input
            height="h-10"
            label="Latitude"
            placeholder="Latitude"
            value={latitude}
            onChange={setLatitude}
          />
          <Input
            height="h-10"
            label="Longitude"
            placeholder="Longitude"
            value={longitude}
            onChange={setLongitude}
          />
          <Input
            height="h-10"
            label="Location Name"
            placeholder="Location Name"
            value={locationName}
            onChange={setLocationName}
          />
          <Input
            height="h-10"
            label="Rating"
            placeholder="Rating"
            value={rating}
            onChange={setRating}
          />
          {showModal && <CategorySelect value={selectedCategory} onChange={setSelectedCategory} />}
          {showModal && (
            <div>
              <label className="leading-5 md:text-sm text-xs font-bold mr-3 flex-1">Tags</label>
              <TagSelect
                isChecked={isChecked}
                selectedTags={selectedTags}
                onCheck={onCheck}
                onCheckRemove={onCheckRemove}
              />
            </div>
          )}
        </div>
      </EditModal>
    </>
  );
};

export default EditAttraction;
