import React from 'react';
import Layout from '@hoc/layouts/Layout';
import TagsIndex from '@components/Tag/TagsIndex';

const TagsPage = () => {
  return (
    <Layout>
      <TagsIndex />
    </Layout>
  );
};

export default TagsPage;
