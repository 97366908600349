import React from 'react';
import BlankModal from '@core/modals/BlankModal';
import { FaCamera } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';

const ImagesModal = ({ show, title, children, onClose, onSubmit, loading, label, ...props }) => {
  return (
    <BlankModal
      show={show}
      title={title}
      onClose={onClose}
      maxWidth="3xl"
      icon={<FaCamera className="text-orange-700" />}
      iconBg="bg-orange-100"
      otherButtons={[
        <DefaultButton
          sm
          bgColor="bg-orange-600"
          bgColorHover="hover:bg-orange-700"
          loading={loading}
          onClick={onSubmit}
          label={
            <div className="flex items-center">
              <FaCamera className="mr-1" />
              Manage
            </div>
          }
        />
      ]}
      {...props}>
      {children}
    </BlankModal>
  );
};
export default ImagesModal;
