import React from 'react';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import EditTag from '@components/Tag/EditTag';
import ShowTag from '@components/Tag/ShowTag';
import TagService from '@services/TagService';

const TagActions = ({ tag, onEdited, onDeleted }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="mr-2">
        <ShowTag tag={tag} />
      </div>
      <div className="mr-2">
        <EditTag tag={tag} onEdited={onEdited} />
      </div>
      <DeleteEntity service={TagService} id={tag.id} onDeleted={onDeleted} />
    </div>
  );
};

export default TagActions;
