import axiosInstance from '@utils/plugins/API';

const TagService = {
  all: (params) => {
    return axiosInstance.get('/tags', { params });
  },
  create: (name) => {
    return axiosInstance.post('/tags', {
      name
    });
  },
  update: (id, name) => {
    return axiosInstance.post(`/tags/${id}/update`, {
      name
    });
  },
  find: (id) => {
    return axiosInstance.get(`/tags/${id}/show`);
  },
  destroy: (id) => {
    return axiosInstance.post(`tags/${id}/delete`);
  }
};

export default TagService;
