import React, { useEffect, useState } from 'react';
import { showError, showSuccess } from '@utils/helpers';
import AttractionService from '@services/AttractionService';
import ImagesModal from '@core/modals/ImagesModal';
import { FaCamera, FaTrashAlt } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import FileUpload from '@core/inputs/FileUpload';

const ManageImages = ({ attraction, onManaged }) => {
  const [files, setFiles] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setUploadFiles([]);
  };

  useEffect(() => {
    if (showModal) {
      AttractionService.find(attraction.id)
        .then((response) => {
          setFiles(response?.data?.data?.images || []);
        })
        .catch(() => {
          showError('Failed to load images');
        });
    }
  }, [attraction.id, showModal]);

  const handleDeleteImage = (file) => {
    setLoading(true);
    AttractionService.deleteImage(file.id)
      .then(() => {
        showSuccess('Image deleted successfully');
        setFiles((prevFiles) => prevFiles.filter((f) => f.id !== file.id));
        setLoading(false);
      })
      .catch((err) => {
        showError(err.response?.data?.message || 'Failed to delete image');
        setLoading(false);
      });
  };

  const handleSetPrimaryImage = (file) => {
    if (file.is_primary) return;
    setLoading(true);
    AttractionService.setPrimaryImage(file.id, file.attraction_id)
      .then(() => {
        showSuccess('Primary image updated successfully');
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === file.id ? { ...f, is_primary: true } : { ...f, is_primary: false }
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        showError(err.response?.data?.message || 'Failed to set primary image');
        setLoading(false);
      });
  };

  const handleUploadImages = () => {
    if (uploadFiles.length === 0) {
      closeModal();
      return;
    }
    setLoading(true);

    const formData = new FormData();
    formData.append('attraction_id', attraction.id);
    uploadFiles.forEach((file, index) => {
      formData.append(`images[${index}]`, file);
    });

    AttractionService.uploadImages(attraction.id, formData)
      .then((response) => {
        showSuccess('Images uploaded successfully');
        setFiles((prevFiles) => [...prevFiles, ...response.data.data.images]);
        setLoading(false);
        onManaged();
        closeModal();
      })
      .catch((err) => {
        showError(err.response?.data?.message || 'Failed to upload images');
        setLoading(false);
      });
  };

  return (
    <>
      <DefaultButton
        sm
        label={
          <div className="flex items-center">
            <FaCamera />
          </div>
        }
        bgColor="bg-orange-100"
        bgColorHover="hover:bg-orange-200"
        textColor="text-orange-700"
        onClick={() => setShowModal(true)}
      />
      <ImagesModal
        onSubmit={handleUploadImages}
        show={showModal}
        title="Manage Images"
        onClose={closeModal}
        loading={loading}>
        <div className="grid grid-cols-3 gap-4 mt-4">
          {files.map((file) => (
            <div
              key={file.id}
              className={`relative group border-4 ${
                file.is_primary ? 'border-blue-500 rounded-xl' : 'border-transparent'
              }`}
              onClick={() => handleSetPrimaryImage(file)}>
              <img
                src={file.url}
                alt="Attraction"
                className="w-full h-32 object-cover rounded-lg shadow-md cursor-pointer"
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteImage(file);
                }}
                className="absolute top-1 right-1 hover:bg-red-700 bg-red-500 text-white rounded-full px-4 py-1 text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <FaTrashAlt className="hover:bg-red-700" />
              </button>
            </div>
          ))}
        </div>
        <div className="max-w-md mx-auto mt-10">
          <FileUpload files={uploadFiles} setFiles={setUploadFiles} />
        </div>
      </ImagesModal>
    </>
  );
};

export default ManageImages;
