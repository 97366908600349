import React from 'react';

const RadioButton = ({ label, value, onChange }) => {
  const handleRadioChange = (value) => {
    onChange(value);
  };

  return (
    <div>
      <div className="font-bold text-center">{label}</div>
      <div className="flex justify-around items-center mt-3">
        <label>
          <input type="radio" value checked={!!value} onChange={() => handleRadioChange(true)} />
          Po
        </label>
        <label>
          <input
            type="radio"
            value={false}
            checked={!value}
            onChange={() => handleRadioChange(false)}
          />
          Jo
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
