import React from 'react';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import ShowCategory from '@components/Category/ShowCategory';
import CategoryService from '@services/CategoryService';
import EditCategory from '@components/Category/EditCategory';

const CategoriesActions = ({ category, onEdited, onDeleted }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="mr-2">
        <ShowCategory category={category} />
      </div>
      <div className="mr-2">
        <EditCategory category={category} onEdited={onEdited} />
      </div>
      <DeleteEntity service={CategoryService} id={category.id} onDeleted={onDeleted} />
    </div>
  );
};

export default CategoriesActions;
