import React, { useState } from 'react';
import AddButton from '@core/buttons/atoms/AddButton';
import AddModal from '@core/modals/AddModal';
import Input from '@core/inputs/Input';
import { showError, showSuccess } from '@utils/helpers';
import CategoryService from '@services/CategoryService';
import ImageInput from '@core/inputs/ImageInput';

const CreateCategory = ({ onAdded }) => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName('');
    setImage(null);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('icon', image);
    setLoading(true);
    CategoryService.create(formData)
      .then(() => {
        showSuccess('Category created successfully');
        onAdded();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <AddButton label="Add category" onClick={() => setShowModal(true)} />
      <AddModal
        show={showModal}
        title="Add category"
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}>
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input height="h-10" label="Name" placeholder="Name" value={name} onChange={setName} />
          <div>
            <label className="leading-5 md:text-sm text-xs font-bold mr-3 flex-1">Icon</label>
            <ImageInput handleChange={(file) => setImage(file)} />
          </div>
        </div>
      </AddModal>
    </>
  );
};

export default CreateCategory;
