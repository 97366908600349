import React, { useEffect, useState } from 'react';
import CategoryService from '@services/CategoryService';
import Select from '@core/dropdowns/Select';

const CategorySelect = ({ value, onChange }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);

  const handleSelectChange = (value) => {
    onChange(value);
  };

  useEffect(() => {
    if (!categoryOptions?.length)
      CategoryService.all().then((categoryResponse) => {
        setCategoryOptions(categoryResponse.data.data.data);
      });
  }, [categoryOptions?.length]);

  return (
    <Select
      label="Category"
      placeholder="Select category"
      value={value}
      options={categoryOptions}
      handleInputChange={handleSelectChange}
    />
  );
};

export default CategorySelect;
