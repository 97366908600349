import React, { useEffect, useState } from 'react';
import ViewModal from '@core/modals/ViewModal';
import ViewButton from '@core/buttons/atoms/ViewButton';
import RowDetails from '@hoc/cruds/RowDetails';
import CategoryService from '@services/CategoryService';

const ShowCategory = ({ category }) => {
  const [showModal, setShowModal] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const baseUrl = process.env.REACT_APP_API_URL.replace('/api/', '');

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      CategoryService.find(category.id).then((response) => {
        setCategoryData(response.data.data);
      });
  }, [category.id, showModal]);

  return (
    <>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal show={showModal} title="Overview" onClose={closeModal}>
        <div className="flex flex-col mt-5">
          <RowDetails label="Name" value={categoryData.name} />
          {categoryData.icon && (
            <div className="flex justify-between px-3 border-b mb-3">
              <div className="text-lg text-gray-500 flex-1" style={{ minWidth: 100 }}>
                Icon
              </div>
              <img
                src={`${baseUrl}${categoryData.icon}`}
                alt="attraction"
                style={{ maxWidth: 300 }}
              />
            </div>
          )}
        </div>
      </ViewModal>
    </>
  );
};

export default ShowCategory;
