import React from 'react';
import Layout from '@hoc/layouts/Layout';
import AttractionsIndex from '@components/Attraction/AttractionsIndex';

const TagsPage = () => {
  return (
    <Layout>
      <AttractionsIndex />
    </Layout>
  );
};

export default TagsPage;
