import React, { useEffect, useState } from 'react';
import ViewModal from '@core/modals/ViewModal';
import ViewButton from '@core/buttons/atoms/ViewButton';
import RowDetails from '@hoc/cruds/RowDetails';
import AttractionService from '@services/AttractionService';

const ShowAttraction = ({ attraction }) => {
  const [showModal, setShowModal] = useState(false);
  const [attractionData, setAttractionData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      AttractionService.find(attraction.id).then((response) => {
        setAttractionData(response.data.data);
      });
  }, [attraction.id, showModal]);

  return (
    <>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal show={showModal} title="Overview" onClose={closeModal}>
        <div className="flex flex-col mt-5">
          <RowDetails label="Full name" value={attractionData.name} />
          <RowDetails label="Subtitle" value={attractionData?.sub_title || '-'} />
          <RowDetails label="Description" value={attractionData.description} />
          <RowDetails label="Rating" value={attractionData.rating} />
          <RowDetails label="Location Name" value={attractionData.location_name} />
          <RowDetails label="Latitude" value={attractionData.latitude} />
          <RowDetails label="Longitude" value={attractionData.longitude} />
          <RowDetails label="Category" value={attractionData?.category?.name} />
          {attractionData?.tags?.map((tag) => (
            <RowDetails label="Tag" value={tag.name} />
          ))}
          {attractionData?.images?.map((image) => (
            <div className="flex justify-between px-3 border-b mb-3">
              <div className="text-lg text-gray-500 flex-1" style={{ minWidth: 100 }}>
                Image
              </div>
              <img src={image.url} alt="attraction" style={{ maxWidth: 300 }} />
            </div>
          ))}
        </div>
      </ViewModal>
    </>
  );
};

export default ShowAttraction;
