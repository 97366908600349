import axiosInstance from '@utils/plugins/API';

const UserService = {
  all: (params) => {
    return axiosInstance.get('/users', { params });
  },
  // eslint-disable-next-line camelcase
  create: (display_name, is_admin, email, password) => {
    return axiosInstance.post('/users', {
      // eslint-disable-next-line camelcase
      display_name,
      // eslint-disable-next-line camelcase
      is_admin,
      email,
      password
    });
  },
  // eslint-disable-next-line camelcase
  update: (id, display_name, is_admin, is_active, email, password) => {
    return axiosInstance.post(`/users/${id}/update`, {
      // eslint-disable-next-line camelcase
      display_name,
      // eslint-disable-next-line camelcase
      is_admin,
      // eslint-disable-next-line camelcase
      is_active,
      email,
      password
    });
  },
  find: (id) => {
    return axiosInstance.get(`/users/${id}/show`);
  },
  destroy: (id) => {
    return axiosInstance.post(`users/${id}/delete`);
  }
};

export default UserService;
