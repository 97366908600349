import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FileUploader } from 'react-drag-drop-files';

const ImageInput = ({ handleChange, allowPreviewImage = true, imagePath, disabled }) => {
  const [previewImage, setPreviewImage] = useState(null);

  const onDelete = () => {
    setPreviewImage(null);
    if (handleChange) {
      handleChange(null);
    }
  };

  const handleFileChange = (file) => {
    setPreviewImage(URL.createObjectURL(file));
    if (handleChange) {
      handleChange(file);
    }
  };

  return (
    <div className="flex flex-col" data-testid="CFile-input">
      <FileUploader
        types={['png', 'jpg', 'jpeg', 'svg']}
        name="file"
        handleChange={handleFileChange}>
        <div className="relative flex rounded-md border-gray-300 border-dashed border-2 bg-gray-100 hover:opacity-75 hover:border-gray-400 justify-center cursor-pointer">
          <div className="flex my-5">
            {imagePath ? (
              <img
                src={imagePath}
                width={240}
                height={240}
                alt={imagePath}
                className={`${disabled && 'grayscale-100'}`}
              />
            ) : (
              'Shtoni një imazh'
            )}
          </div>
        </div>
      </FileUploader>
      {previewImage && (
        <div className="relative flex items-center justify-center border p-5 bg-gray-100 mt-3 rounded-md">
          {allowPreviewImage && (
            <div className="flex h-24 justify-center mb-1">
              <img className="shadow-md hover:scale-110" src={previewImage} alt="logo" />
            </div>
          )}
          <div className="absolute top-2 right-2">
            <div
              className="h-5 font-bold hover:cursor-pointer rounded-full hover:text-marketplace hover:bg-gray-200"
              onClick={onDelete}>
              X
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageInput;
