import React from 'react';

const RowDetails = ({ value, label }) => {
  return (
    <div className="flex justify-between px-3 border-b mb-3">
      <div className="text-lg text-gray-500 flex-1">{label}</div>
      <div className="text-lg text-gray-800 flex-1 text-right font-semibold">{value}</div>
    </div>
  );
};
export default RowDetails;
