import React from 'react';
import { FaFileAlt, FaHome, FaTag, FaUsers, FaList } from 'react-icons/fa';
import SidebarItem from '@core/items/SidebarItem';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="h-screen sticky top-0 min-w-max w-56 bg-white border hidden lg:block relative">
      <Link to="/" className="h-16 flex items-center justify-center">
        <h1>LOGO</h1>
      </Link>
      <ul className="flex flex-col py-4 mt-6 h-screen overflow-y-auto">
        <SidebarItem icon={<FaHome />} label="Home" link="/" exact />
        <SidebarItem icon={<FaList />} label="Attractions" link="/attractions" exact />
        <SidebarItem icon={<FaFileAlt />} label="Categories" link="/categories" exact />
        <SidebarItem icon={<FaTag />} label="Tags" link="/tags" exact />
        <SidebarItem icon={<FaUsers />} label="Users" link="/users" exact />
        <div className="py-10" />
      </ul>
    </aside>
  );
};

export default Sidebar;
