import axiosInstance from '@utils/plugins/API';

const CategoryService = {
  all: (params) => {
    return axiosInstance.get('/categories', { params });
  },
  create: (payload) => {
    return axiosInstance.post('/categories', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  update: (id, payload) => {
    return axiosInstance.post(`/categories/${id}/update`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  find: (id) => {
    return axiosInstance.get(`/categories/${id}/show`);
  },
  destroy: (id) => {
    return axiosInstance.post(`categories/${id}/delete`);
  }
};

export default CategoryService;
