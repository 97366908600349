import React, { useState } from 'react';
import AddButton from '@core/buttons/atoms/AddButton';
import AddModal from '@core/modals/AddModal';
import Input from '@core/inputs/Input';
import UserService from '@services/UserService';
import { showError, showSuccess } from '@utils/helpers';
import RadioButton from '@core/radio/RadioButton';

const CreateUser = ({ onAdded }) => {
  const [showModal, setShowModal] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setDisplayName('');
    setPassword('');
    setEmail('');
  };

  const handleRadioButton = (value) => {
    setIsAdmin(value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    UserService.create(displayName, isAdmin, email, password)
      .then(() => {
        showSuccess('User created successfully');
        onAdded();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <AddButton label="Add user" onClick={() => setShowModal(true)} />
      <AddModal
        show={showModal}
        title="Add user"
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}>
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label="Display name"
            placeholder="Display name"
            value={displayName}
            onChange={setDisplayName}
          />
          <Input
            type="email"
            height="h-10"
            label="Email"
            placeholder="Email"
            value={email}
            onChange={setEmail}
          />
          <Input
            height="h-10"
            type="password"
            label="Password"
            value={password}
            placeholder="Password"
            className="rounded-t-none"
            onChange={setPassword}
          />
          <RadioButton label="Është administrator?" value={isAdmin} onChange={handleRadioButton} />
        </div>
      </AddModal>
    </>
  );
};

export default CreateUser;
