import React, { useEffect, useState } from 'react';
import EditModal from '@core/modals/EditModal';
import EditButton from '@core/buttons/atoms/EditButton';
import Input from '@core/inputs/Input';
import UserService from '@services/UserService';
import { showError, showSuccess } from '@utils/helpers';
import RadioButton from '@core/radio/RadioButton';

const EditUser = ({ user, onEdited }) => {
  const [showModal, setShowModal] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setDisplayName('');
    setPassword('');
    setEmail('');
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    UserService.update(user.id, displayName, isAdmin, isActive, email, password)
      .then(() => {
        showSuccess('User edited successfully');
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      UserService.find(user.id).then((response) => {
        setDisplayName(response.data.data.display_name);
        setEmail(response.data.data.email);
        if (response.data.data.is_active) {
          setIsActive(true);
        }
        if (response.data.data.is_admin) {
          setIsAdmin(true);
        }
      });
  }, [user.id, showModal]);

  const handleRadioButton = (value) => {
    setIsActive(value);
  };

  const handleIsAdminRadioButton = (value) => {
    setIsAdmin(value);
  };

  return (
    <>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title="Edit user"
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label="Edit">
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label="Display name"
            placeholder="Display name"
            value={displayName}
            onChange={setDisplayName}
          />
          <Input
            type="email"
            height="h-10"
            label="Email"
            placeholder="Email"
            value={email}
            onChange={setEmail}
          />
          <Input
            height="h-10"
            type="password"
            label="Password"
            value={password}
            placeholder="Password"
            className="rounded-t-none"
            onChange={setPassword}
          />
          <RadioButton label="Është aktiv?" value={isActive} onChange={handleRadioButton} />
          <RadioButton
            label="Është administrator?"
            value={isAdmin}
            onChange={handleIsAdminRadioButton}
          />
        </div>
      </EditModal>
    </>
  );
};

export default EditUser;
