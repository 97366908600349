import Axios from 'axios';
import { API_URL } from '@constants/index';

const token = localStorage.getItem('accessToken');

const axiosInstance = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('accessToken');

      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
