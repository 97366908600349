import React from 'react';
import Layout from '@hoc/layouts/Layout';
import CategoriesIndex from '@components/Category/CategoriesIndex';

const CategoriesPage = () => {
  return (
    <Layout>
      <CategoriesIndex />
    </Layout>
  );
};

export default CategoriesPage;
