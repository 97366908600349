import GuestAPI from '@utils/plugins/GuestAPI';
import API from '@utils/plugins/API';

const AuthService = {
  login: (email, password) => {
    return GuestAPI.post('login', {
      email,
      password
    });
  },
  adminLogin: (email, password) => {
    return GuestAPI.post(`admin-login`, {
      email,
      password
    });
  },
  deleteAccount: (email, password) => {
    return GuestAPI.post(`delete-account`, {
      email,
      password
    });
  },
  changePassword: (password, passwordConfirmation) => {
    return API.patch('users/change-my-password', {
      password,
      passwordConfirmation
    });
  }
};

export default AuthService;
