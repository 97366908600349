import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import FileUploading from 'react-files-uploading';

const FileUpload = ({ files, setFiles, maxNumber = 3 }) => {
  return (
    <FileUploading
      acceptType={['jpg', 'jpeg', 'svg', 'png']}
      multiple
      value={files}
      maxNumber={maxNumber}
      onChange={setFiles}>
      {({
        fileList,
        errors,
        isDragging,
        onFileUpload,
        onFileRemoveAll,
        onFileUpdate,
        onFileRemove,
        dragProps
      }) => (
        <div className="upload__file-wrapper p-4 border border-gray-300 rounded-lg bg-white">
          {errors && errors.maxNumber && (
            <span className="text-red-500 text-sm">
              Number of selected files exceeds the maximum allowed
            </span>
          )}
          <div className="mt-4">
            {fileList.map((file, index) => (
              <div
                key={`file-${index}`}
                className="file-item flex justify-between items-center bg-gray-100 p-2 mb-2 rounded-md shadow-sm">
                <p className="text-sm text-gray-700">{file.name}</p>
                <div className=" flex gap-2">
                  <button
                    id={`update_${index}`}
                    type="button"
                    className="px-3 py-1 bg-emerald-100 text-emerald-700 font-bold text-xs rounded-md hover:bg-emerald-200"
                    onClick={() => onFileUpdate(index)}>
                    Update
                  </button>
                  <button
                    id={`remove_${index}`}
                    type="button"
                    className="px-3 py-1 bg-red-100 text-red-700 font-bold text-xs rounded-md hover:bg-red-300"
                    onClick={() => onFileRemove(index)}>
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center gap-5 mt-4">
            <button
              type="button"
              className={`text-blue-500 font-bold text-sm hover:text-blue-600 focus:outline-none ${
                isDragging ? 'border-b border-red-500' : ''
              }`}
              onClick={onFileUpload}
              {...dragProps}>
              Upload Image
            </button>

            {fileList.length > 0 && (
              <button
                id="btn-remove"
                type="button"
                className="text-red-500 font-bold text-sm hover:text-red-600 focus:outline-none"
                onClick={onFileRemoveAll}>
                Remove all files
              </button>
            )}
          </div>
        </div>
      )}
    </FileUploading>
  );
};

export default FileUpload;
