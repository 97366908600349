import React from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const UserDropdown = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    navigate('/login');
  };

  return (
    <div
      onClick={handleLogout}
      className="px-6 py-3 text-base text-red-600 cursor-pointer rounded-lg hover:bg-red-50 text-left flex">
      <AiOutlineLogout className="mr-3 mt-1" />
      Dil
    </div>
  );
};

export default UserDropdown;
